exports.components = {
  "component---src-new-templates-beach-js": () => import("./../../../src/newTemplates/Beach.js" /* webpackChunkName: "component---src-new-templates-beach-js" */),
  "component---src-new-templates-beaches-js": () => import("./../../../src/newTemplates/Beaches.js" /* webpackChunkName: "component---src-new-templates-beaches-js" */),
  "component---src-new-templates-report-js": () => import("./../../../src/newTemplates/Report.js" /* webpackChunkName: "component---src-new-templates-report-js" */),
  "component---src-new-templates-reports-js": () => import("./../../../src/newTemplates/Reports.js" /* webpackChunkName: "component---src-new-templates-reports-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */)
}

